.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20vh auto 20vh auto;
}

.middleRectangle {
  height: 450px;
  width: 350px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(98, 105, 113, 0.25);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 40px 20px;
}

.logoContainer {
  height: 38px;
  width: 100px;
  margin-bottom: 40px;
}

.loginLogo {
  height: 38px;
  width: 80px;
}

.loginButton {
  height: 44px;
  width: 250px;
  border-radius: 4px;
  border: none;
  background-color: #47cdff;
  color: #ffffff;
  margin: 30px auto 10px;
}

.loginText {
  font-size: 21px;
  font-weight: 500;
}
