.button-open-modal {
  height: 40px;
  width: 100%;
  min-width: 100px;
  background-color: #47cdff;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  padding: 5px;
}

.button-open-modal:focus,
.button-open-modal:active {
  box-shadow: none;
  outline: thin dotted #47cdff;
}

.modalDims {
  min-width: 690px;
  max-width: 690px;
  max-height: 90vh;
  margin: 0 auto 0;
  border-radius: 0.3rem;
}
