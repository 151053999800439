.formInput-compareTo {
  font-weight: bold;
  margin: 0 4px;
  padding: 0 4px;
  border: 1px solid #d1d8dc;
  border-radius: 2px;
}

.formInput-compareEqual {
  background-color: #befdce8f;
}

.formInput-compareNotEqual {
  background-color: #f9fdbe8f;
}

.formRow {
  display: flex;
  justify-content: space-between;
  margin: 5px 30px 5px;
}

.formRowLeft {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 5px 30px 5px;
}

.formRowRight {
  display: flex;
  justify-content: flex-end;
  margin: 5px 30px 5px;
}

.formInput {
  height: 51px;
  width: 301px;
  border: 1px solid #d1d8dc;
  border-radius: 2px;
  padding: 16px;
}

.formInput:active,
.formInput:focus {
  outline: thin dotted #47cdff;
}

.formButton {
  padding: 4px;
  max-width: 200px;
  border: none;
  border-radius: 4px;
  background-color: #47cdff;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  margin: 5px;
}

.formButton:active,
.formButton:focus {
  border: none;
  box-shadow: none;
  outline: thin dotted #47cdff;
}

.formButton:disabled {
  border: 1px solid #47cdff;
  background-color: #7d858b;
  cursor: not-allowed;
}

.formField {
  color: #424e55;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  letter-spacing: 0;
}

.errorText {
  color: red;
}
