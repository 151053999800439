.visitModalDims {
  min-width: 690px;
  max-width: 80vw;
  height: 90vh;
  margin: 0 auto 0;
  border-radius: 0.3rem;
}

.visitModalDims > * {
  height: 100%;
}

.splitModalBody {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.linkButton {
  border: none;
  color: blue;
  text-decoration: underline;
}

.leftColumn {
  width: 49%;
  height: 100%;
  overflow-y: scroll;
  float: left;
  border-top: 0.5px solid black;
  border-right: 0.5px solid black;
}

.rightColumn {
  width: 49%;
  height: 100%;
  overflow-y: scroll;
  float: right;
  border-left: 0.5px solid black;
}

.topRight {
  overflow-y: scroll;
  height: 50%;
  padding: 0 5px 5px;
  border-top: 0.5px solid black;
}

.bottomRight {
  overflow-y: scroll;
  height: 50%;
  border-top: 0.5px solid black;
  padding: 0 5px 5px;
}

.horizontalDivider {
  border: 1px solid grey;
  margin: 5px 0;
  width: 100%;
}

.sectionHeader {
  position: sticky;
  top: 0;
  background-color: white;
  font-size: 20px;
  font-weight: bold;
}

.stackedRow {
  display: flex;
  flex-direction: column;
}

.isRejected td {
  background-color: rgb(222, 101, 101);
}

.stretchWord {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.acceptButton {
  height: fit-content;
  width: fit-content;
  background-color: #47cdff;
  border: none;
  border-radius: 4px;
  color: black;
  padding: 5px;
  margin: 0 5px;
  opacity: 100%;
  font-size: 20px;
}

.acceptButton:focus,
.acceptButton:active {
  box-shadow: none;
  outline: thin dotted #47cdff;
}
