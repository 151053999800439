.visits-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.visits-page th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.searchButton {
  height: 40px;
  width: 100%;
  min-width: 100px;
  background-color: #47cdff;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  padding: 5px;
  margin: 2px 0;
}

.searchButton:focus,
.searchButton:active {
  box-shadow: none;
  outline: thin dotted #47cdff;
}
