.dashboard-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.dashboard-page .tab-content,
.dashboard-page .tab-content .active {
  display: flex;
  overflow: auto;
  width: 100%;
  flex-grow: 1;
}
