.App {
  display: flex;
  flex-direction: column;
  background-color: #f6f7fa;
  height: 100vh;
  font-family: "Montserrat", sans-serif;
}

.mapContainerClass {
  display: block;
}

.modalHeader {
  padding: 10px 10px;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  height: 57px;
}

.modalTitle {
  color: #2b2d34;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  letter-spacing: 0;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modalCloseButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.modalCloseButton:active,
.modalCloseButton:focus {
  border: none;
  box-shadow: none;
  outline: thin dotted #47cdff;
}

.xIcon {
  height: 24px;
  width: 24px;
}

.modalBody {
  display: flex;
  flex-direction: column;
  height: calc(100% - 57px);
  padding: 10px 10px;
  font-family: "Montserrat", sans-serif;
}

.modalFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modalFooter > * {
  margin: 0 5px;
}

button {
  filter: brightness(100%);
  transition: all 0.3s;
}

button:hover {
  filter: brightness(96%);
}

/* custom theme for bootstrap
 https://react-bootstrap.netlify.app/docs/getting-started/theming */
.btn-beluga-primary {
  background-color: #47cdff;
  color: #ffffff;
}

.btn-beluga-primary:hover {
  background-color: #47cdff;
  color: #ffffff;
}

.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
